import React from "react";
import Button from 'react-bootstrap/Button'
import './SearchBox.css';

export default class SearchBox extends React.Component<any, any> {
    address: string = '';
    
    streetAddressChange(e: any) {
        this.address = e.target.value;
    }

    render() {
        return (
            <div>
                <form onSubmit={ (event: any) => this.props.submit(event, this.address) }>
                    <input id="search-input" type="text" placeholder="Enter an address..." onChange={ (event: any) => this.streetAddressChange(event)}></input>
                    <Button id="search-button" type="submit">Search</Button>
                </form>
            </div>
        );
    }
}