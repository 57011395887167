import { waitFor } from "@testing-library/dom";
import { nextTick } from "process";
import React from "react";
import { GisResult } from "../interfaces/GisResult";
import ResultDisplay from "./result-display/ResultDisplay";
import './SearchResults.css';

export default class SearchResults extends React.Component<any, any> {
    results: GisResult[] = this.props.results;

    constructor(props: any) {
        super(props);
        this.state = {
            selectedResult: null
        }
    }

    resultSelected(result: GisResult) {
        if (this.state.selectedResult === result) {
            this.setState({selectedResult: null});
        } else {
            this.setState({selectedResult: result});
        }
    }

    render() {
        return(
            <div className="container">
                <div className="results">
                    <div className="results-header">
                        <h1>{this.results.length} Result(s)</h1>
                        <hr/>
                    </div>
                    <div className="scroll-content">
                        {this.results ? <div>
                            {this.results && this.results.map((result) => {
                                return (
                                    <p key={result.value} onClick={ () => this.resultSelected(result) }
                                        className={`result-item ${this.state.selectedResult === result ? "selected" : ""}`}
                                    >{result.value}</p>
                                )
                            })}
                        </div>
                        :<p>Loading...</p>}
                    </div>
                </div>
                <div className="details">
                    {this.state.selectedResult ?
                        <ResultDisplay result={ this.state.selectedResult }/>
                    :
                    <h3 className="select-result">Select a Result to View Details</h3>
                    }
                </div>
            </div>
        );
    }
}