import Map, { Marker } from 'react-map-gl';
import pin from '../../../assets/images/pin.png';
import appSetting from '../../../appSetting.json';

export default function MapDisplay(props: any) {
    return (
        <Map
            mapboxAccessToken={appSetting.mapboxToken}
            initialViewState={props.viewPoint}
            mapStyle="mapbox://styles/mapbox/streets-v11"
            >
                <Marker key="point" longitude={props.longitude} latitude={props.latitude}>
                    <img src={ pin } style={{height: '20px'}} alt="pin"/>
                </Marker>
        </Map>
    );
}