import React from 'react';
import logo from './assets/images/citySeal.svg';
import './App.css';
import SearchBox from './search-box/SearchBox';
import SearchResults from './search-results/SearchResults';
import GisAddressSearch from './gis-service/GisService';
import GettingStarted from './getting-started/GettingStarted';
import { Col, Container, Row } from 'react-bootstrap';

export default class App extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      searchAddress: '',
      results: null
    };
  }

  search(event: any, newAddress: string) {
    event.preventDefault();
    this.setState({searchAddress: newAddress, results: null});
  }

  setResults(results: any) {
    this.setState({searchAddress: this.state.searchAddress, results: results});
  }

  pageContent() {
    if(this.state.searchAddress && !this.state.results) {
      return (
        <div> 
          <GisAddressSearch 
            searchText={ this.state.searchAddress }
            setResults={ (results: any) => this.setResults(results) }
          />
        </div>
      );
    }
    else if (this.state.results && this.state.results) {
      return ( <SearchResults results={ this.state.results }/> );
    }
    else {
      return ( <GettingStarted></GettingStarted> );
    }
  }

  render() {
    return (
      <Container fluid>
        <Row className="header">
          <Col sm={false} className="header-branding">
            <img src={logo} className="logo" alt="logo" />
            <p className="header-text" >Birmingham Address Lookup</p>
          </Col>
          <Col>
            <SearchBox 
              searchAddress={ this.state.searchAddress }
              submit={ (event: any, result: any) => this.search(event, result) }
            />
          </Col>
        </Row>
        <Row className="page-content">
          {this.pageContent()}
        </Row>
      </Container>
    );
  }
}
