import * as React from "react";
import { GisResult } from "../interfaces/GisResult";
import './GisService.css';

export default function GisAddressSearch(props: any) {
    const searchText = props.searchText;
    const [error, setError] = React.useState("");

    React.useEffect(() => {
        const fetchResults = async () => {
            try {
                const url = `https://gisweb.birminghamal.gov/arcgis/rest/services/CRM/CRM_Address_Point2/MapServer/find?searchText=${searchText}&contains=true&searchFields=ADDR&sr=&layers=0&layerDefs=&returnGeometry=true&maxAllowableOffset=&geometryPrecision=&dynamicLayers=&returnZ=false&returnM=false&gdbVersion=&f=json`;
                const res = await fetch(url);
                const data = await res.json();

                props.setResults(data.results as GisResult)
            } catch (error: any) {
                setError(error.toString());
            }
        };
        
        fetchResults();
    });    
    
    return (
        <div className="content">
            <h1>Loading...</h1>
            {error && <p className="error">{error}</p>}
        </div>
  );
}