import React from "react";
import './GettingStarted.css';

export default class GettingStarted extends React.Component<any, any> {

    render() {
        return (
            <div className="content">
                <h1>Search an Address to Get Started</h1>
            </div>
        )
    }
}