import React from "react";
import './ResultDisplay.css';
import MapDisplay from "./map-display/MapDisplay";
import 'mapbox-gl/dist/mapbox-gl.css';

export default class ResultDisplay extends React.Component<any, any> {
    mapViewport: any;

    constructor(props: any) {
        super(props);
        this.state = {
            result: this.props.result
        };
    }
    

    render() {

        return (
            <div>
                <h1>{ this.props.result.value }</h1>
                <hr/>
                <div className="details-container">
                    <div>
                        {this.zipCode()}
                        {this.rowLot()}
                        {this.district()}
                        {this.floodplain()}
                        {this.communityName()}
                        {this.neighborhoodName()}
                        {this.refuseDays()}
                        {this.refuseRoute()}
                        {this.refuseRouteNumber()}
                        {this.sanitationDistrict()}
                        {this.brushAndTrashDistrict()}
                        {this.environmentCodeZone()}
                        {this.horticultureDistrict()}
                        {this.forestryDistrict()}
                    </div>
                    {this.getMap()}
                </div>
            </div>
        )
    }

    getMap() {
        return(
            <div className="map">
                <MapDisplay 
                    key={this.props.result.value}
                    longitude={this.props.result.attributes.POINT_X} 
                    latitude={this.props.result.attributes.POINT_Y}
                    viewPoint={{
                        longitude: this.props.result.attributes.POINT_X,
                        latitude: this.props.result.attributes.POINT_Y,
                        zoom: 15
                    }}
                ></MapDisplay>
            </div>
        )
    }

    zipCode() {
        const label = "Zip Code";
        const displayText = this.props.result.attributes.ZIPP !== "Null" ? this.props.result.attributes.ZIPP : `Not in ${label}`;
        return this.item(label, displayText);
    }

    rowLot() {
        const label = "ROW/Lot";
        const displayText = this.props.result.attributes["ROW-LOT_ZONE"] !== "Null" ? this.props.result.attributes["ROW-LOT_ZONE"] : `Not in ${label}`;
        return this.item(label, displayText);
    }

    district() {
        const label = "Council District";
        const displayText = this.props.result.attributes.DISTRICT !== "Null" ? this.props.result.attributes.DISTRICT : `Not in ${label}`;
        return this.item(label, displayText);
    }

    floodplain() {
        const label = "Floodplain";
        const displayText = this.props.result.attributes.FLOODPLAIN !== "Null" ? this.props.result.attributes.FLOODPLAIN : `Not in ${label}`;
        return this.item(label, displayText);
    }

    horticultureDistrict() {
        const label = "Horticulture District";
        const displayText = this.props.result.attributes.HORT_DIST !== "Null" ? this.props.result.attributes.HORT_DIST : `Not in ${label}`;
        return this.item(label, displayText);
    }

    communityName() {
        const label = "Community Name";
        const displayText = this.props.result.attributes.COMMUNITY_NAME !== "Null" ? this.props.result.attributes.COMMUNITY_NAME : `Not in ${label}`;
        return this.item(label, displayText);
    }

    neighborhoodName() {
        const label = "Neighborhood Name";
        const displayText = this.props.result.attributes.NEIGHBORHOOD_NAME !== "Null" ? this.props.result.attributes.NEIGHBORHOOD_NAME : `Not in ${label}`;
        return this.item(label, displayText);
    }

    sanitationDistrict() {
        const label = "Sanitation District";
        const displayText = this.props.result.attributes.SANITATION_DISTRICT !== "Null" ? this.props.result.attributes.SANITATION_DISTRICT : `Not in ${label}`;
        return this.item(label, displayText);
    }

    refuseDays() {
        const label = "Refuse Days";
        const displayText = this.props.result.attributes.DAYS !== "Null" ? this.props.result.attributes.DAYS : `Not in ${label}`;
        return this.item(label, displayText);
    }

    refuseRoute() {
        const label = "Refuse Route";
        const displayText = this.props.result.attributes.REF_AREA !== "Null" ? this.props.result.attributes.REF_AREA : `Not in ${label}`;
        return this.item(label, displayText);
    }

    refuseRouteNumber() {
        const label = "Refuse Route Number";
        const displayText = this.props.result.attributes.ROUTE !== "Null" ? this.props.result.attributes.ROUTE : `Not in ${label}`;
        return this.item(label, displayText);
    }

    environmentCodeZone() {
        const label = "Environment Code Zone";
        const displayText = this.props.result.attributes.ENV_CODE_ZONE !== "Null" ? this.props.result.attributes.ENV_CODE_ZONE : `Not in ${label}`;
        return this.item(label, displayText);
    }

    brushAndTrashDistrict() {
        const label = "Brush and Trash District";
        const displayText = this.props.result.attributes.BRUSH_AND_TRASH_DISTRICT !== "Null" ? this.props.result.attributes.BRUSH_AND_TRASH_DISTRICT : `Not in ${label}`;
        return this.item(label, displayText);
    }

    forestryDistrict() {
        const label = "Forestry District";
        const displayText = this.props.result.attributes.FORESTRY_DISTRICT !== "Null" ? this.props.result.attributes.FORESTRY_DISTRICT : `Not in ${label}`;
        return this.item(label, displayText);
    }

    item(label: string, value: string) {
        return (
            <div>
                <p><strong>{ label }: </strong>{ value }</p>
            </div>
        )
    }
}